import firebase from "gatsby-plugin-firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HomeAppBar from "../components/HomeAppBar";
import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import { DataGrid } from '@mui/x-data-grid';

export default class SeasonList extends React.Component {

    state={
        seasonList: [],
        rows:[]
    }
    componentDidMount() {
        firebase.database()
            .ref("seasons").limitToLast(10).on("value", (data) => {
                console.log(data.val())

                this.setState({seasonList:data.val(), rows: Object.keys(data.val()).map((key,index)=>{
                    //
                    return (
                       {...data.val()[key], id: index}
                    )
                })})
            })
        
    }

    seasonRow = (props) =>{
        console.log(props)
        return (
         
            <TableRow style={{textDecoration:"none"}} hover component={"a"} href={"/editseason?id="+props.id} >
                <TableCell>{props.seasonName}</TableCell>
                <TableCell>{props.seasonTime}</TableCell>
                <TableCell>{props.seasonStartDate}</TableCell>
                <TableCell>{props.seasonEndDate}</TableCell>
                <TableCell><Link to={"/editseason?id="+props.id} >Edit</Link></TableCell>
            </TableRow>
        )
    }
    columns=[
        {
            field: 'seasonName',
            headerName: 'Last name',
            width: 150,
            editable: true,
          },
          {
            field: 'seasonTime',
            headerName: 'Last name',
            width: 150,
            editable: true,
          },
          {
            field: 'seasonStartDate',
            headerName: 'Last name',
            type: 'date',
            width: 150,
            editable: true,
          },
          {
            field: 'seasonEndDate',
            headerName: 'Last name',
            type: 'date',
            width: 150,
            editable: true,
          },
    ]

    rows = Object.keys(this.state.seasonList).map((key,index)=>{
        console.log(key)
        return (
           this.state.seasonList[key]
        )
    })


    render(){
        return(
            <div>
                <HomeAppBar title="LA Ping Pong Season List" />
                <a href="/createnewseason">Create a new season</a>
               <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Season</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Action</TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(this.state.seasonList).map((key,index)=>{
                            console.log(key)
                            return (
                                <this.seasonRow id={key} {...this.state.seasonList[key]}></this.seasonRow>
                            )
                        })
                    }
              
                </TableBody>
            </Table> 
            {

            //    <DataGrid style={{height:400}}
            //     rows={this.state.rows}
            //     columns={this.columns}

            // ></DataGrid> 
            }
            
            </div>
            
        )
    }
}